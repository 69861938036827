/* Museo Font */
@import url("https://use.typekit.net/rth8fnl.css");



.light-text {
  color: #fff;
}

.Heading {
  background: url(./../../Assets/main_bg_50.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* height: auto; */
  height: 100vh;
}



.Heading header.container-fluid {
  /* height: 100vh; */
  position: relative;
}

.video-wrapper {

  /* height: 100vh; */
  position: relative;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.video-wrapper video {
  /* position: absolute; */
  /* top: 25%;
  left: 50%;
  transform: translate(-50%, -50%); */
  min-width: 100%;
  min-height: 100vh;
}


.head-title {
  margin-top: 3%;
  font-family: museo-sans-condensed, sans-serif;
  font-weight: 900;
  font-style: italic;
  text-align: center;
  font-size: 3.8em;
  text-shadow: 2px 2px 2px #000;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
}

.head-title main-text {
  display: block;
}

.hint {
  color: #ebd417;
}

.title-wrapper {
  height: auto;
  /* margin-top: 20%; */
  position: absolute;
  top: 30%;
  right: 0%;
  left: 0%;

}

.down-pointer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 20%;
  margin: auto;
}

.up {
  margin-left: auto;
  margin-right: auto;
  width: 1px;
  height: 1px;
  border-style: inset;
  border-width: 0 30px 40px 30px;
  border-color: transparent transparent#316D93 transparent;
  /* float: left; */
  transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
}

.up img {
  text-align: center;
  top: 22px;
  left: -7.5px;
  position: relative;
  width: 15px;
  height: 15px;
  margin: 0px;
  display: block;
}

.leftBracket {
  font-size: 15em;
  color: #efd812;
  line-height: 0;
}

.quoteWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: space-around;
}



@-webkit-keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 50%;
	}
	50% {
	opacity: 0;
	top: 50%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}
@-moz-keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 50%;
	}
	50% {
	opacity: 0;
	top: 50%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}
@keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 50%;
	}
	50% {
	opacity: 0;
	top: 50%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}
 .scroll-btn {
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
  position: absolute;
  top: 80%;
  left: 0;
  right: 0;
}
.scroll-btn > * {
	display: inline-block;
	line-height: 18px;
	font-size: 13px;
	font-weight: normal;
	color: #7f8c8d;
	color: #ffffff;
	font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
	letter-spacing: 2px;
}
.scroll-btn > *:hover,
.scroll-btn > *:focus,
.scroll-btn > *.active {
	color: #ffffff;
}
.scroll-btn > *:hover,
.scroll-btn > *:focus,
.scroll-btn > *:active,
.scroll-btn > *.active {
	opacity: 0.8;
	filter: alpha(opacity=80);
}
.scroll-btn .mouse {
	position: relative;
	display: block;
	width: 35px;
	height: 55px;
	margin: 0 auto 20px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	border: 3px solid white;
	border-radius: 23px;
}
.scroll-btn .mouse > * {
	position: absolute;
	display: block;
	top: 29%;
	left: 50%;
	width: 8px;
	height: 8px;
	margin: -4px 0 0 -4px;
	background: white;
	border-radius: 50%;
	-webkit-animation: ani-mouse 2.5s linear infinite;
	-moz-animation: ani-mouse 2.5s linear infinite;
	animation: ani-mouse 2.5s linear infinite;
}

.scroll-btn > p {
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
  font-size: 1.4em;
}

.ghost-wrapper {
  height: auto;
  width: 100%;
  position: relative;
  top: 0;
  z-index: 1;
  text-align: center;
}

.ghost-wrapper a.ghost-home {
  margin-top: 8%;
}


a.anchor {
    display: block;
    position: relative;
    top: -160px;
    visibility: hidden;
}

@media (max-width: 1025px) {
  .video-wrapper video {
    display: none;
  }
  .video-wrapper {
    height: 100%;
  }
  .leftBracket {
    display: none;
  }

  .title-wrapper {
    height: 100%;
    position: static;
    margin-left: auto;
    margin-right: auto;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .head-title {
    font-size: 2em;
    margin-left: auto;
    margin-right: auto;
  }

  .scroll-btn {
    bottom: 10%;
  }
}



.hb-cta, .undefined {
  display: none !important;
}
