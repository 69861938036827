@import url(https://fonts.googleapis.com/css?family=Raleway);

/* *,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
} */

/* .wrap {
  width: 100%;
  max-width: 900px;
  margin: 4em auto;
  font-family: Raleway, Arial, sans-serif;
  padding: 1em 2em;
} */

/* hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 2px solid #eee;
  margin: 2em 0;
  padding: 0;
} */

/* Buttons styles */
input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input[type="submit"].ghost,
button.ghost {
  cursor: pointer;
}

a.ghost,
.ghost {
  display: inline-block;
  outline: none;
  *zoom: 1;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  font-weight: 500;
  letter-spacing: 1px;
  vertical-align: middle;
  border: 1px solid;
  transition: all 0.2s ease;
  box-sizing: border-box;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.01);
}
/* Radius */
.ghost-radius {
  border-radius: 3px;
}
/* Sizes */
.ghost-small {
  font-size: 0.8125em;
  padding: 0.4125em 1.25em;
}
.ghost-medium {
  font-size: 0.9375em;
  padding: 0.5375em 1.375em;
}
.ghost-large {
  font-size: 1.0625em;
  padding: 0.5625em 1.5em;
}

/* Colors */
.ghost-green {
  color: #3cb371;
  border-color: #3cb371;
}
.ghost-green:hover {
  background: #3cb371;
  color: #fff;
  border-color: #3cb371;
}

.ghost-blue {
  color: #4682b4;
  border-color: #4682b4;
}
.ghost-blue:hover {
  background: #4682b4;
  color: #fff;
  border-color: #4682b4;
}

.ghost-home {
  width: auto;
  color: #fff;
  border-color: #fff;
  border-width: 2px;
  padding: 10px 10px;
  text-transform: uppercase;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
}
.ghost-home:hover {
  background: #4682b4;
  color: #fff;
  border-color: #4682b4;
}

.ghost-orange {
  color: #ff8c00;
  border-color: #ff8c00;
}
.ghost-orange:hover {
  background: #ff8c00;
  color: #fff;
  border-color: #ff8c00;
}

.ghost-red {
  color: #b22222;
  border-color: #b22222;
}
.ghost-red:hover {
  background: #b22222;
  color: #fff;
  border-color: #b22222;
}

.ghost-gray {
  color: #808080;
  border-color: #808080;
}
.ghost-gray:hover {
  background: #808080;
  color: #fff;
  border-color: #808080;
}
