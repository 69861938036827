
.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: #ddd;
}

.circle-progress {
  stroke: red;
  stroke-linecap: butt;
  stroke-linejoin: round;
  transition: all 0.5s ease;

}

.circle-text {
  font-size: 3em;
  font-family: museo-sans-condensed, sans-serif;
  font-weight: 900;
  font-style: italic;
}
