/* .Contact {
  margin-bottom: 80px;
} */

.contact-heading h1 {
  text-transform: uppercase;
  margin: auto;
  text-align: center;
  font-family: museo-sans-condensed, sans-serif;
  font-weight: 900;
  text-align: center;
  font-size: 3.5em;
  color: #316d93;
  margin-top: 20px;
  display: block;
}

.contact-heading p {
  font-family: museo-sans-condensed, sans-serif;
  font-weight: 500;
  font-style: italic;
  text-align: center;
  font-size: 1em;
  color: #316d93;
}

.cf {
  background-color: #316d93;
  background: url(./../../../Assets/contacts_image.jpg) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
}

.cf p {
  font-family: open-sans, sans-serif;
  font-weight: 400;
  font-size: 1.25em;
  color: #313e51;
}

.cf-info {
  margin-top: 38px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

span.cf-info-sub {
  font-family: open-sans, sans-serif;
  font-weight: 700;
  font-size: 1em;
  color: #EFD914;
  padding-right: 16px;
}

.cf-info img {
  padding-right: 16px;
  height: 35px;
}

.cf-info a {
  color: #fff;
  font-family: open-sans, sans-serif;
  font-weight: 500;
}

img.cf-social {
  padding-right: 25px;
}

/* a.cf-phone {
  color: #8bb902;
  font-weight: bold;
  margin-left: auto;
} */

.quote {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
}

.home-form-wrapper, .cf-wrapper {
  max-width: 600px;
}

.cf-wrapper {
  margin: 0;
  margin-left: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 5em;
  padding-right: 5em;
}

.cf-wrapper h2 {
  color: #EFD914;
  text-align: center;
  font-family: museo-sans-condensed, sans-serif;
  font-weight: 700;
  text-align: left;
  font-size: 3em;
  margin-top: 60px;
  display: block;
}

.home-form-wrapper {
  /* max-width: 0px; */
  /* margin-left: auto;
  margin-right: auto; */
  padding-bottom: 5em;
}

.home-form-wrapper h2 {
  /* text-transform: uppercase; */
  /* margin: auto; */
  text-align: center;
  font-family: museo-sans-condensed, sans-serif;
  font-weight: 700;
  font-size: 3em;
  color: #316d93;
  margin-top: 60px;
  display: block;
}

.home-form-wrapper span.sub-text {
  display: block;
  text-align: center;
}

.cf-wrapper .head-desc {
  color: #fff;
  font-family: museo-sans-condensed, sans-serif;
  font-weight: 500;
}

section.main_content {
  margin-top: 3em;
}

.formThanks {
  display: inline-block;
  font-weight: bold !important;
  font-size: 4em !important;
  vertical-align: top;
}

.btn-submit {
  display: block;
  margin: 0 auto;
  background-color: #316D93;
  border-radius: 0;
}

.contact-quote {
  margin-top: auto;
  text-align: center;
  font-family: museo-sans-condensed, sans-serif;
  font-weight: 500;
  font-size: 2em;
  color: #fff;
  display: block;
  position: relative;
  /* padding-bottom: 5em; */
}

.contact-quote span {
  font-family: museo-sans-condensed, sans-serif;
  font-weight: 700;
  color: #EFD914;
  ;
}


#form-input-timeframe {
  position: relative;
    /* top: -.35em; */
    border: none;
    background: rgba(140, 140, 140, 0.2);
    outline: none;
    margin: 1em .25em 0 0;
    font-size: 1.125rem;
    line-height: 2em;
    padding: .7em .75em .5em;
    width: 18.75rem;
    max-width: 100%;
    -webkit-transition: background .3s ease;
    transition: background .3s ease;
    height: 50px;
    border-radius: 0;
    -webkit-border-radius: 0;
}

@media (max-width: 1280px) {
  .cf-wrapper {
    padding-right: 0;
  }
  .home-form-wrapper {
    margin-left: 30px;
  }
}

@media (max-width: 760px) {
  .cf-wrapper {
    margin-right: auto;
  }

  .contact-quote {
    margin-top: 20px;
  }
  .home-form-wrapper {
    margin-left: auto;
    margin-right: auto;
  }

  .home-form-wrapper .main_content {
    /* text-align: center; */
    margin-top: 1em;
  }

  #form-input-timeframe {
    width: 25%;
  }
}

iframe {
  margin-top: 30px;
  margin-bottom: 30px
}

.all_form {
  /* background-color: blue; */
  text-align: right;
}
