

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *    Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */
}

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal;
}

/**
 * It's recommended that you don't attempt to style these elements.
 * Firefox's implementation doesn't respect box-sizing, padding, or width.
 *
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto;
}

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold;
}

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

html ::selection {
  background: #fdf7c3;
  /* background: #EED712; */
}

html ::-moz-selection {
  background: #fdf7c3;
}

.clear:before,
.clear:after {
  content: "";
  display: table;
}

.clear:after {
  clear: both;
}

svg {
  max-height: 100%;
}

img, svg {
  max-width: 100%;
}

.contain_all {
  width: 100%;
  overflow: hidden;
}

.contain {
  max-width: 76em;
  padding: 0 1.5em;
  margin: 0 auto;
}

.contain.small, .contain.aside_left .content, .contain.share_right .content {
  max-width: 51.125em;
}

.contain.mid {
  max-width: 65.5em;
}

.contain.large {
  padding: 0;
  max-width: 82.25em;
}

.contain.side_nav {
  min-height: 30em;
}

@media (min-width: 50.125em) {
  .contain aside hr {
    display: none;
  }
  .contain.side_nav {
    position: relative;
    padding-left: 26.9375em;
  }
  .contain.side_nav aside {
    position: absolute;
    top: 0;
    left: 0;
    width: 18.8125em;
    padding: 0 1.5em;
  }
  .contain.aside_left {
    position: relative;
    padding-left: 19.75em;
  }
  .contain.aside_left aside {
    position: absolute;
    top: 0;
    left: 0;
    width: 18.75em;
    padding: 0 4.5em 0 1.5em;
    margin-bottom: 0;
  }
  .contain.aside_left aside.pushed_down {
    padding-top: 2.375em;
  }
  .contain.aside_left.mid aside {
    padding: 0 1.5em;
  }
  .contain.aside_right {
    position: relative;
    padding-right: 26.9375em;
  }
  .contain.aside_right aside {
    position: absolute;
    top: 0;
    right: 0;
    width: 26.9375em;
    padding: 0 1.5em 0 8.125em;
    margin-bottom: 0;
  }
  .contain.aside_right aside.pushed_down {
    padding-top: 2.375em;
  }
  .contain.aside_both {
    position: relative;
    padding-left: 13.9375em;
    padding-right: 20.1875em;
    margin-bottom: 7.5em;
  }
  .contain.aside_both aside {
    position: absolute;
    top: 0;
    width: 12.5625em;
  }
  .contain.aside_both aside.left {
    left: 0;
    padding: 0 0 0 1.5em;
  }
  .contain.aside_both aside.right {
    right: 0;
    padding: 0 1.5em 0 0;
    margin-bottom: 0;
  }
  .contain.share_right {
    position: relative;
    padding-right: 19.75em;
  }
  .contain.share_right aside {
    position: absolute;
    top: 0;
    right: 0;
    width: 19.75em;
    padding: 0 1.5em 0 7.5em;
    margin-bottom: 0;
  }
}

.elements_contain {
  max-width: 71em;
  margin: 0 auto;
  padding: 3em 1.5em;
}

.element_block {
  margin-bottom: 4em;
}

.element_block .element_head {
  position: relative;
  font-family: Sailec-Regular;
  color: #666665;
  margin-bottom: 2em;
  padding-bottom: 1em;
  font-size: .75em;
  text-transform: uppercase;
}

.element_block .element_head::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 3.5em;
  border-bottom: 1px solid #cbc8c4;
}

.figure_block {
  overflow: hidden;
  margin-bottom: 5em;
}

figure {
  margin: 0 0 1em;
}

figure img {
  display: block;
}

figure figcaption {
  display: block;
  text-align: left;
  font-size: .75em;
  letter-spacing: .1em;
  text-transform: uppercase;
  max-width: 75rem;
  padding: 1rem 1rem 0;
  margin: 0 auto;
}

figure figcaption a {
  color: #ff5c5c;
  text-decoration: none;
  border-bottom: 1px solid #ff5c5c;
  transition: color .3s ease, border-color .3s ease;
}

figure figcaption a:hover {
  color: #f50000;
  border-color: #f50000;
}

@media (min-width: 50.125em) {
  figure figcaption {
    text-align: right;
  }
}

.figure_one_up_small {
  margin-bottom: 2em;
}

.figure_two_up {
  overflow: hidden;
}

@media (min-width: 40.625em) {
  .figure_two_up figure {
    float: left;
    width: 49.5%;
    margin-right: 1%;
  }
  .figure_two_up figure:last-child {
    margin-right: 0;
  }
  .figure_two_up figure figcaption {
    max-width: 34rem;
  }
}

input::-webkit-input-placeholder {
  color: #666665;
}

input::-moz-placeholder {
  color: #666665;
}

/* firefox 19+ */
input:-ms-input-placeholder {
  color: #666665;
}

/* ie */
input:-moz-placeholder {
  color: #666665;
}

form input[type=text], form input[type=email] {
  position: relative;
  /* top: -.35em; */
  border: none;
  background: rgba(140, 140, 140, 0.2);
  outline: none;
  margin: 1em .25em 0 0;
  font-size: 1.125rem;
  line-height: 2em;
  padding: .7em .75em .5em;
  width: 18.75rem;
  max-width: 100%;
  transition: background .3s ease;
}

form input[type=text]:focus {
  background: white;
  border-bottom: 1px solid rgba(140, 140, 140, 0.2);
}

form input[type=text].small {
  width: 10rem;
}

form input[type=submit] {
  float: right;
  background: none;
  border: none;
  border-bottom: 1px solid #ff5c5c;
  color: #ff5c5c;
  padding: 0;
  margin: 0;
  outline: none;
  font-size: 3.375em;
  font-family: FreightDispProBook-Regular;
  transition: color .3s ease, border-color .3s ease;
}

form input[type=submit]:hover {
  color: #f50000;
  border-color: #f50000;
}
