@import url(https://use.typekit.net/rth8fnl.css);
@import url(https://fonts.googleapis.com/css?family=Raleway);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Museo Font */



.light-text {
  color: #fff;
}

.Heading {
  background: url(/static/media/main_bg_50.09324a62.jpg) no-repeat center center fixed;
  background-size: cover;
  /* height: auto; */
  height: 100vh;
}



.Heading header.container-fluid {
  /* height: 100vh; */
  position: relative;
}

.video-wrapper {

  /* height: 100vh; */
  position: relative;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.video-wrapper video {
  /* position: absolute; */
  /* top: 25%;
  left: 50%;
  transform: translate(-50%, -50%); */
  min-width: 100%;
  min-height: 100vh;
}


.head-title {
  margin-top: 3%;
  font-family: museo-sans-condensed, sans-serif;
  font-weight: 900;
  font-style: italic;
  text-align: center;
  font-size: 3.8em;
  text-shadow: 2px 2px 2px #000;
  text-transform: uppercase;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.head-title main-text {
  display: block;
}

.hint {
  color: #ebd417;
}

.title-wrapper {
  height: auto;
  /* margin-top: 20%; */
  position: absolute;
  top: 30%;
  right: 0%;
  left: 0%;

}

.down-pointer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 20%;
  margin: auto;
}

.up {
  margin-left: auto;
  margin-right: auto;
  width: 1px;
  height: 1px;
  border-style: inset;
  border-width: 0 30px 40px 30px;
  border-color: transparent transparent#316D93 transparent;
  /* float: left; */
  transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
}

.up img {
  text-align: center;
  top: 22px;
  left: -7.5px;
  position: relative;
  width: 15px;
  height: 15px;
  margin: 0px;
  display: block;
}

.leftBracket {
  font-size: 15em;
  color: #efd812;
  line-height: 0;
}

.quoteWrap {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-grow: space-around;
          flex-grow: space-around;
}



@-webkit-keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 50%;
	}
	50% {
	opacity: 0;
	top: 50%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}
@keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 50%;
	}
	50% {
	opacity: 0;
	top: 50%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}
 .scroll-btn {
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
  position: absolute;
  top: 80%;
  left: 0;
  right: 0;
}
.scroll-btn > * {
	display: inline-block;
	line-height: 18px;
	font-size: 13px;
	font-weight: normal;
	color: #7f8c8d;
	color: #ffffff;
	font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
	letter-spacing: 2px;
}
.scroll-btn > *:hover,
.scroll-btn > *:focus,
.scroll-btn > *.active {
	color: #ffffff;
}
.scroll-btn > *:hover,
.scroll-btn > *:focus,
.scroll-btn > *:active,
.scroll-btn > *.active {
	opacity: 0.8;
	filter: alpha(opacity=80);
}
.scroll-btn .mouse {
	position: relative;
	display: block;
	width: 35px;
	height: 55px;
	margin: 0 auto 20px;
	box-sizing: border-box;
	border: 3px solid white;
	border-radius: 23px;
}
.scroll-btn .mouse > * {
	position: absolute;
	display: block;
	top: 29%;
	left: 50%;
	width: 8px;
	height: 8px;
	margin: -4px 0 0 -4px;
	background: white;
	border-radius: 50%;
	-webkit-animation: ani-mouse 2.5s linear infinite;
	animation: ani-mouse 2.5s linear infinite;
}

.scroll-btn > p {
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
  font-size: 1.4em;
}

.ghost-wrapper {
  height: auto;
  width: 100%;
  position: relative;
  top: 0;
  z-index: 1;
  text-align: center;
}

.ghost-wrapper a.ghost-home {
  margin-top: 8%;
}


a.anchor {
    display: block;
    position: relative;
    top: -160px;
    visibility: hidden;
}

@media (max-width: 1025px) {
  .video-wrapper video {
    display: none;
  }
  .video-wrapper {
    height: 100%;
  }
  .leftBracket {
    display: none;
  }

  .title-wrapper {
    height: 100%;
    position: static;
    margin-left: auto;
    margin-right: auto;
    margin-left: auto;
    margin-right: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .head-title {
    font-size: 2em;
    margin-left: auto;
    margin-right: auto;
  }

  .scroll-btn {
    bottom: 10%;
  }
}



.hb-cta, .undefined {
  display: none !important;
}

.navbar {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.active-nav {
  background-color: rgba(0, 0, 0, 0.70);
}


.header-bar-link:hover:after {
    visibility: visible;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
}

.header-bar-link:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: -2px;
    left: 0;
    right: 0;
    background-color: #fff;
    visibility: hidden;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transition: all .2s cubic-bezier(1,.25,0,.75) 0s;
    transition: all .2s cubic-bezier(1,.25,0,.75) 0s;}

/* Tranparent Navbar */
.navbar.transparent.navbar-inverse .navbar-inner {
  border-width: 0px;
  box-shadow: 0px 0px;
  background-color: rgba(0, 0, 0, 0);
  background-image: -webkit-gradient(
    linear,
    50% 0%,
    50% 100%,
    color-stop(0%, rgba(0, 0, 0, 0)),
    color-stop(100%, rgba(0, 0, 0, 0))
  );
  background-image: -webkit-linear-gradient(
    270deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-image: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.home-logo {
  height: 120px;
  margin-left: 10px;
  padding: 10px 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.active-nav > .navbar-brand > .home-logo {
  padding: 5px 0;
  height: 90px;
}

.nav-link {
  font-size: 1.4em;
  font-weight: bold;
  color: #fff;
  text-transform: capitalize;
  margin: 0 1rem;
  position: relative;
  cursor: pointer;
}

/* .active-nav > .nav-link {
  font-size: 1.2em;
  color: red;
} */






@media (max-width: 1025px) {
  .active-nav > .navbar-brand >.home-logo {
    padding: 15px 0;
    height: 90px;
  }

  .home-logo {

    padding: 10px 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }



  .navbar-collapse ul {
    background-color: rgba(0, 0, 0, 0.70);
    width: 100%;
    margin-left: 15px;
    margin-right: 15px;
  }

}

.Wwd.container-fluid {
  background-color: #316d93;
  padding-bottom: 50px;
  position: relative;
}

h1.wwd-title {
  text-transform: uppercase;
  margin: auto;
  text-align: center;
  font-family: museo-sans-condensed, sans-serif;
  font-weight: 500;
  text-align: center;
  font-size: 3.5em;
  color: #fff;
  margin-top: 50px;
  display: block;
}

.bottom-border-short {
  width: 200px;
  height: 5px;
  background-color: #fff;
  margin: auto;
  padding: 0;
  display: block;
  margin-top: 45px;
}

.wwd-content p {
  /* width: 90%; */
  margin-top: 45px;
  color: #fff;
  font-family: museo-sans-condensed, sans-serif;
  font-weight: 500;
  text-align: center;
  font-size: 1.25em;
  margin-bottom: 0;
}

.wwd-show .wwd-icon {
  height: 100px;
  margin-top: 35px;
  padding-left: 20px;
}

.wwd-show .wwd-icon-cube {
  padding: 0;
}
.wwd-show span {
  font-family: museo-sans-condensed, sans-serif;
  font-weight: 600;
  text-align: center;
  font-size: 1.5em;
  color: #fff;
  text-align: center;
  margin-top: 20px;
}

.wwd-show p {
  width: 80%;
  font-family: museo-sans-condensed, sans-serif;
  font-weight: 500;
  font-style: italic;
  text-align: center;
  font-size: 1.3em;
  color: #fff;
  text-align: center;
  margin-top: 20px;
}

.About.container-fluid {
  background-color: #316d93;
  background: url(/static/media/about_overlay_80.b0d58b4c.jpg) no-repeat center center
    fixed;
  background-size: cover;
  background-position: center center;
  padding-bottom: 80px;
}

.down-pointer-alt .up {
  border-color: transparent transparent #171e26 transparent;
  margin-top: 80px;
}


.about-btn {
  margin-top: 8%;
}

.Trends.container-fluid {
  background-color: #316d93;
  background: url(/static/media/trends-bg.09f2dd47.jpg) no-repeat center center fixed;
  background-size: cover;
  background-position: top top;
}

.trend-icon {
  width: 100%;
  margin-top: 45px;
}

.alt-trend {
  margin-top: 165px;
}

p.trends-text {
  color: #fff;
  margin-top: 30px;
  text-align: center;
  font-size: 0.9em;
}

.progress-wrapper {
  text-align: center;
}

.progress-wrapper svg {
  margin-top: 20px
}

.progress-wrapper:nth-child(even) svg {
  margin-top: 100px;
  /* background-color: red; */
}

@media (max-width: 1025px) {
  .progress-wrapper:nth-child(even) svg {
    margin-top: 20px;
  }

  .progress-wrapper {
    padding: 0 10px;
  }
}


.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: #ddd;
}

.circle-progress {
  stroke: red;
  stroke-linecap: butt;
  stroke-linejoin: round;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;

}

.circle-text {
  font-size: 3em;
  font-family: museo-sans-condensed, sans-serif;
  font-weight: 900;
  font-style: italic;
}

.team {
  margin-top: 40px;
}

.Mtt.container-fluid {
  background-color: #316d93;
  padding: 0;
  padding-bottom: 80px;

}

.down-pointer-mtt .up {
  border-color: transparent transparent #fff transparent;
  margin-top: 80px;
}

/* .team-pic {
  width
} */


.card-img-top {
  min-height: 402px;
}

/* .Contact {
  margin-bottom: 80px;
} */

.contact-heading h1 {
  text-transform: uppercase;
  margin: auto;
  text-align: center;
  font-family: museo-sans-condensed, sans-serif;
  font-weight: 900;
  text-align: center;
  font-size: 3.5em;
  color: #316d93;
  margin-top: 20px;
  display: block;
}

.contact-heading p {
  font-family: museo-sans-condensed, sans-serif;
  font-weight: 500;
  font-style: italic;
  text-align: center;
  font-size: 1em;
  color: #316d93;
}

.cf {
  background-color: #316d93;
  background: url(/static/media/contacts_image.8705cf68.jpg) no-repeat center center;
  background-size: cover;
  background-position: center center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.cf p {
  font-family: open-sans, sans-serif;
  font-weight: 400;
  font-size: 1.25em;
  color: #313e51;
}

.cf-info {
  margin-top: 38px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

span.cf-info-sub {
  font-family: open-sans, sans-serif;
  font-weight: 700;
  font-size: 1em;
  color: #EFD914;
  padding-right: 16px;
}

.cf-info img {
  padding-right: 16px;
  height: 35px;
}

.cf-info a {
  color: #fff;
  font-family: open-sans, sans-serif;
  font-weight: 500;
}

img.cf-social {
  padding-right: 25px;
}

/* a.cf-phone {
  color: #8bb902;
  font-weight: bold;
  margin-left: auto;
} */

.quote {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
}

.home-form-wrapper, .cf-wrapper {
  max-width: 600px;
}

.cf-wrapper {
  margin: 0;
  margin-left: auto;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-bottom: 5em;
  padding-right: 5em;
}

.cf-wrapper h2 {
  color: #EFD914;
  text-align: center;
  font-family: museo-sans-condensed, sans-serif;
  font-weight: 700;
  text-align: left;
  font-size: 3em;
  margin-top: 60px;
  display: block;
}

.home-form-wrapper {
  /* max-width: 0px; */
  /* margin-left: auto;
  margin-right: auto; */
  padding-bottom: 5em;
}

.home-form-wrapper h2 {
  /* text-transform: uppercase; */
  /* margin: auto; */
  text-align: center;
  font-family: museo-sans-condensed, sans-serif;
  font-weight: 700;
  font-size: 3em;
  color: #316d93;
  margin-top: 60px;
  display: block;
}

.home-form-wrapper span.sub-text {
  display: block;
  text-align: center;
}

.cf-wrapper .head-desc {
  color: #fff;
  font-family: museo-sans-condensed, sans-serif;
  font-weight: 500;
}

section.main_content {
  margin-top: 3em;
}

.formThanks {
  display: inline-block;
  font-weight: bold !important;
  font-size: 4em !important;
  vertical-align: top;
}

.btn-submit {
  display: block;
  margin: 0 auto;
  background-color: #316D93;
  border-radius: 0;
}

.contact-quote {
  margin-top: auto;
  text-align: center;
  font-family: museo-sans-condensed, sans-serif;
  font-weight: 500;
  font-size: 2em;
  color: #fff;
  display: block;
  position: relative;
  /* padding-bottom: 5em; */
}

.contact-quote span {
  font-family: museo-sans-condensed, sans-serif;
  font-weight: 700;
  color: #EFD914;
  ;
}


#form-input-timeframe {
  position: relative;
    /* top: -.35em; */
    border: none;
    background: rgba(140, 140, 140, 0.2);
    outline: none;
    margin: 1em .25em 0 0;
    font-size: 1.125rem;
    line-height: 2em;
    padding: .7em .75em .5em;
    width: 18.75rem;
    max-width: 100%;
    -webkit-transition: background .3s ease;
    transition: background .3s ease;
    height: 50px;
    border-radius: 0;
    -webkit-border-radius: 0;
}

@media (max-width: 1280px) {
  .cf-wrapper {
    padding-right: 0;
  }
  .home-form-wrapper {
    margin-left: 30px;
  }
}

@media (max-width: 760px) {
  .cf-wrapper {
    margin-right: auto;
  }

  .contact-quote {
    margin-top: 20px;
  }
  .home-form-wrapper {
    margin-left: auto;
    margin-right: auto;
  }

  .home-form-wrapper .main_content {
    /* text-align: center; */
    margin-top: 1em;
  }

  #form-input-timeframe {
    width: 25%;
  }
}

iframe {
  margin-top: 30px;
  margin-bottom: 30px
}

.all_form {
  /* background-color: blue; */
  text-align: right;
}



/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *    Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */
}

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal;
}

/**
 * It's recommended that you don't attempt to style these elements.
 * Firefox's implementation doesn't respect box-sizing, padding, or width.
 *
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto;
}

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold;
}

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

html ::-moz-selection {
  background: #fdf7c3;
  /* background: #EED712; */
}

html ::selection {
  background: #fdf7c3;
  /* background: #EED712; */
}

html ::-moz-selection {
  background: #fdf7c3;
}

.MainForm_clear__1SHzG:before,
.MainForm_clear__1SHzG:after {
  content: "";
  display: table;
}

.MainForm_clear__1SHzG:after {
  clear: both;
}

svg {
  max-height: 100%;
}

img, svg {
  max-width: 100%;
}

.MainForm_contain_all__37ztL {
  width: 100%;
  overflow: hidden;
}

.MainForm_contain__3QGIp {
  max-width: 76em;
  padding: 0 1.5em;
  margin: 0 auto;
}

.MainForm_contain__3QGIp.MainForm_small__2a7Ck, .MainForm_contain__3QGIp.MainForm_aside_left__I-z89 .MainForm_content__12Jcj, .MainForm_contain__3QGIp.MainForm_share_right__tXFB4 .MainForm_content__12Jcj {
  max-width: 51.125em;
}

.MainForm_contain__3QGIp.MainForm_mid__-k8Oo {
  max-width: 65.5em;
}

.MainForm_contain__3QGIp.MainForm_large__3lCA2 {
  padding: 0;
  max-width: 82.25em;
}

.MainForm_contain__3QGIp.MainForm_side_nav__6n0Ju {
  min-height: 30em;
}

@media (min-width: 50.125em) {
  .MainForm_contain__3QGIp aside hr {
    display: none;
  }
  .MainForm_contain__3QGIp.MainForm_side_nav__6n0Ju {
    position: relative;
    padding-left: 26.9375em;
  }
  .MainForm_contain__3QGIp.MainForm_side_nav__6n0Ju aside {
    position: absolute;
    top: 0;
    left: 0;
    width: 18.8125em;
    padding: 0 1.5em;
  }
  .MainForm_contain__3QGIp.MainForm_aside_left__I-z89 {
    position: relative;
    padding-left: 19.75em;
  }
  .MainForm_contain__3QGIp.MainForm_aside_left__I-z89 aside {
    position: absolute;
    top: 0;
    left: 0;
    width: 18.75em;
    padding: 0 4.5em 0 1.5em;
    margin-bottom: 0;
  }
  .MainForm_contain__3QGIp.MainForm_aside_left__I-z89 aside.MainForm_pushed_down__1sXU- {
    padding-top: 2.375em;
  }
  .MainForm_contain__3QGIp.MainForm_aside_left__I-z89.MainForm_mid__-k8Oo aside {
    padding: 0 1.5em;
  }
  .MainForm_contain__3QGIp.MainForm_aside_right__QBjMN {
    position: relative;
    padding-right: 26.9375em;
  }
  .MainForm_contain__3QGIp.MainForm_aside_right__QBjMN aside {
    position: absolute;
    top: 0;
    right: 0;
    width: 26.9375em;
    padding: 0 1.5em 0 8.125em;
    margin-bottom: 0;
  }
  .MainForm_contain__3QGIp.MainForm_aside_right__QBjMN aside.MainForm_pushed_down__1sXU- {
    padding-top: 2.375em;
  }
  .MainForm_contain__3QGIp.MainForm_aside_both__1C5A- {
    position: relative;
    padding-left: 13.9375em;
    padding-right: 20.1875em;
    margin-bottom: 7.5em;
  }
  .MainForm_contain__3QGIp.MainForm_aside_both__1C5A- aside {
    position: absolute;
    top: 0;
    width: 12.5625em;
  }
  .MainForm_contain__3QGIp.MainForm_aside_both__1C5A- aside.MainForm_left__3VykD {
    left: 0;
    padding: 0 0 0 1.5em;
  }
  .MainForm_contain__3QGIp.MainForm_aside_both__1C5A- aside.MainForm_right__2pYsZ {
    right: 0;
    padding: 0 1.5em 0 0;
    margin-bottom: 0;
  }
  .MainForm_contain__3QGIp.MainForm_share_right__tXFB4 {
    position: relative;
    padding-right: 19.75em;
  }
  .MainForm_contain__3QGIp.MainForm_share_right__tXFB4 aside {
    position: absolute;
    top: 0;
    right: 0;
    width: 19.75em;
    padding: 0 1.5em 0 7.5em;
    margin-bottom: 0;
  }
}

.MainForm_elements_contain___-2eW {
  max-width: 71em;
  margin: 0 auto;
  padding: 3em 1.5em;
}

.MainForm_element_block__2vVkh {
  margin-bottom: 4em;
}

.MainForm_element_block__2vVkh .MainForm_element_head__39Fqz {
  position: relative;
  font-family: Sailec-Regular;
  color: #666665;
  margin-bottom: 2em;
  padding-bottom: 1em;
  font-size: .75em;
  text-transform: uppercase;
}

.MainForm_element_block__2vVkh .MainForm_element_head__39Fqz::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 3.5em;
  border-bottom: 1px solid #cbc8c4;
}

.MainForm_figure_block__2NS3U {
  overflow: hidden;
  margin-bottom: 5em;
}

figure {
  margin: 0 0 1em;
}

figure img {
  display: block;
}

figure figcaption {
  display: block;
  text-align: left;
  font-size: .75em;
  letter-spacing: .1em;
  text-transform: uppercase;
  max-width: 75rem;
  padding: 1rem 1rem 0;
  margin: 0 auto;
}

figure figcaption a {
  color: #ff5c5c;
  text-decoration: none;
  border-bottom: 1px solid #ff5c5c;
  -webkit-transition: color .3s ease, border-color .3s ease;
  transition: color .3s ease, border-color .3s ease;
}

figure figcaption a:hover {
  color: #f50000;
  border-color: #f50000;
}

@media (min-width: 50.125em) {
  figure figcaption {
    text-align: right;
  }
}

.MainForm_figure_one_up_small__-uA-P {
  margin-bottom: 2em;
}

.MainForm_figure_two_up__2d8fR {
  overflow: hidden;
}

@media (min-width: 40.625em) {
  .MainForm_figure_two_up__2d8fR figure {
    float: left;
    width: 49.5%;
    margin-right: 1%;
  }
  .MainForm_figure_two_up__2d8fR figure:last-child {
    margin-right: 0;
  }
  .MainForm_figure_two_up__2d8fR figure figcaption {
    max-width: 34rem;
  }
}

input::-webkit-input-placeholder {
  color: #666665;
}

input::-moz-placeholder {
  color: #666665;
}

/* firefox 19+ */
input:-ms-input-placeholder {
  color: #666665;
}

/* ie */
input:-moz-placeholder {
  color: #666665;
}

form input[type=text], form input[type=email] {
  position: relative;
  /* top: -.35em; */
  border: none;
  background: rgba(140, 140, 140, 0.2);
  outline: none;
  margin: 1em .25em 0 0;
  font-size: 1.125rem;
  line-height: 2em;
  padding: .7em .75em .5em;
  width: 18.75rem;
  max-width: 100%;
  -webkit-transition: background .3s ease;
  transition: background .3s ease;
}

form input[type=text]:focus {
  background: white;
  border-bottom: 1px solid rgba(140, 140, 140, 0.2);
}

form input[type=text].MainForm_small__2a7Ck {
  width: 10rem;
}

form input[type=submit] {
  float: right;
  background: none;
  border: none;
  border-bottom: 1px solid #ff5c5c;
  color: #ff5c5c;
  padding: 0;
  margin: 0;
  outline: none;
  font-size: 3.375em;
  font-family: FreightDispProBook-Regular;
  -webkit-transition: color .3s ease, border-color .3s ease;
  transition: color .3s ease, border-color .3s ease;
}

form input[type=submit]:hover {
  color: #f50000;
  border-color: #f50000;
}

/*
.primaryModal {
 background-color: red;
} */

.topExit
{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.topExit button {
  border: 0;
  background: #1682D4;
  color: #fff;
  padding: 15px;
  margin-left: auto;
}

.modalHeading
{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.modalHeading img {
  width: 25%;
  height: auto;
  /* margin-left: auto; */
  margin-top: 0px;
}

.modalHeading h2
{
  margin-top: 3%;
}

.modalInfo {
  width: 80%;
  margin: auto;
  margin-top: 3%;
  text-align: center;
}

.Footer {
  background-color: #292929;
  color: #fff;
}

.footer-nav {
  margin: 50px 0;
}

.footer-nav ul {
  padding: 0;
  margin: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.footer-nav ul li{
  list-style: none;
}

.footer-nav ul li a {
  color: #fff;
  font-family: museo-sans-condensed, sans-serif;
  font-weight: 700;
  /* font-style: italic; */
  text-align: center;
  font-size: 1em;
  /* text-shadow: 2px 2px 2px #000; */
  text-transform: uppercase;
}

.footer-social {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 30px;
  /* margin: 30px 15px; */
}

.footer-social img {
  height: 60px;
  width: 60px;
  padding: 10px;
  /* margin: 20px; */
}
.footer-social-line {
  width: 50%;
  height: 1px;
  background-color: #4D5054;
  margin: 0 10px
}


@media (max-width: 1025px) {
  .footer-nav ul {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }

  .footer-nav ul li{
    padding: 5px;
  }
}

/* *,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
} */

/* .wrap {
  width: 100%;
  max-width: 900px;
  margin: 4em auto;
  font-family: Raleway, Arial, sans-serif;
  padding: 1em 2em;
} */

/* hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 2px solid #eee;
  margin: 2em 0;
  padding: 0;
} */

/* Buttons styles */
input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input[type="submit"].ghost,
button.ghost {
  cursor: pointer;
}

a.ghost,
.ghost {
  display: inline-block;
  outline: none;
  *zoom: 1;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  font-weight: 500;
  letter-spacing: 1px;
  vertical-align: middle;
  border: 1px solid;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  box-sizing: border-box;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.01);
}
/* Radius */
.ghost-radius {
  border-radius: 3px;
}
/* Sizes */
.ghost-small {
  font-size: 0.8125em;
  padding: 0.4125em 1.25em;
}
.ghost-medium {
  font-size: 0.9375em;
  padding: 0.5375em 1.375em;
}
.ghost-large {
  font-size: 1.0625em;
  padding: 0.5625em 1.5em;
}

/* Colors */
.ghost-green {
  color: #3cb371;
  border-color: #3cb371;
}
.ghost-green:hover {
  background: #3cb371;
  color: #fff;
  border-color: #3cb371;
}

.ghost-blue {
  color: #4682b4;
  border-color: #4682b4;
}
.ghost-blue:hover {
  background: #4682b4;
  color: #fff;
  border-color: #4682b4;
}

.ghost-home {
  width: auto;
  color: #fff;
  border-color: #fff;
  border-width: 2px;
  padding: 10px 10px;
  text-transform: uppercase;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
}
.ghost-home:hover {
  background: #4682b4;
  color: #fff;
  border-color: #4682b4;
}

.ghost-orange {
  color: #ff8c00;
  border-color: #ff8c00;
}
.ghost-orange:hover {
  background: #ff8c00;
  color: #fff;
  border-color: #ff8c00;
}

.ghost-red {
  color: #b22222;
  border-color: #b22222;
}
.ghost-red:hover {
  background: #b22222;
  color: #fff;
  border-color: #b22222;
}

.ghost-gray {
  color: #808080;
  border-color: #808080;
}
.ghost-gray:hover {
  background: #808080;
  color: #fff;
  border-color: #808080;
}

