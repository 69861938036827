.About.container-fluid {
  background-color: #316d93;
  background: url(./../../Assets/about_overlay_80.jpg) no-repeat center center
    fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  padding-bottom: 80px;
}

.down-pointer-alt .up {
  border-color: transparent transparent #171e26 transparent;
  margin-top: 80px;
}


.about-btn {
  margin-top: 8%;
}
