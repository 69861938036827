.navbar {
  transition: all 0.5s ease;
}

.active-nav {
  background-color: rgba(0, 0, 0, 0.70);
}


.header-bar-link:hover:after {
    visibility: visible;
    transform: scaleX(1);
}

.header-bar-link:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: -2px;
    left: 0;
    right: 0;
    background-color: #fff;
    visibility: hidden;
    transform: scaleX(0);
    transition: all .2s cubic-bezier(1,.25,0,.75) 0s;}

/* Tranparent Navbar */
.navbar.transparent.navbar-inverse .navbar-inner {
  border-width: 0px;
  -webkit-box-shadow: 0px 0px;
  box-shadow: 0px 0px;
  background-color: rgba(0, 0, 0, 0);
  background-image: -webkit-gradient(
    linear,
    50% 0%,
    50% 100%,
    color-stop(0%, rgba(0, 0, 0, 0)),
    color-stop(100%, rgba(0, 0, 0, 0))
  );
  background-image: -webkit-linear-gradient(
    270deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.home-logo {
  height: 120px;
  margin-left: 10px;
  padding: 10px 0;
  transition: all 0.5s ease;
}

.active-nav > .navbar-brand > .home-logo {
  padding: 5px 0;
  height: 90px;
}

.nav-link {
  font-size: 1.4em;
  font-weight: bold;
  color: #fff;
  text-transform: capitalize;
  margin: 0 1rem;
  position: relative;
  cursor: pointer;
}

/* .active-nav > .nav-link {
  font-size: 1.2em;
  color: red;
} */






@media (max-width: 1025px) {
  .active-nav > .navbar-brand >.home-logo {
    padding: 15px 0;
    height: 90px;
  }

  .home-logo {

    padding: 10px 0;
    transition: all 0.5s ease;
  }



  .navbar-collapse ul {
    background-color: rgba(0, 0, 0, 0.70);
    width: 100%;
    margin-left: 15px;
    margin-right: 15px;
  }

}
