.Footer {
  background-color: #292929;
  color: #fff;
}

.footer-nav {
  margin: 50px 0;
}

.footer-nav ul {
  padding: 0;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.footer-nav ul li{
  list-style: none;
}

.footer-nav ul li a {
  color: #fff;
  font-family: museo-sans-condensed, sans-serif;
  font-weight: 700;
  /* font-style: italic; */
  text-align: center;
  font-size: 1em;
  /* text-shadow: 2px 2px 2px #000; */
  text-transform: uppercase;
}

.footer-social {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  /* margin: 30px 15px; */
}

.footer-social img {
  height: 60px;
  width: 60px;
  padding: 10px;
  /* margin: 20px; */
}
.footer-social-line {
  width: 50%;
  height: 1px;
  background-color: #4D5054;
  margin: 0 10px
}


@media (max-width: 1025px) {
  .footer-nav ul {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer-nav ul li{
    padding: 5px;
  }
}
