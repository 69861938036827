/*
.primaryModal {
 background-color: red;
} */

.topExit
{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.topExit button {
  border: 0;
  background: #1682D4;
  color: #fff;
  padding: 15px;
  margin-left: auto;
}

.modalHeading
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modalHeading img {
  width: 25%;
  height: auto;
  /* margin-left: auto; */
  margin-top: 0px;
}

.modalHeading h2
{
  margin-top: 3%;
}

.modalInfo {
  width: 80%;
  margin: auto;
  margin-top: 3%;
  text-align: center;
}
