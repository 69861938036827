.Wwd.container-fluid {
  background-color: #316d93;
  padding-bottom: 50px;
  position: relative;
}

h1.wwd-title {
  text-transform: uppercase;
  margin: auto;
  text-align: center;
  font-family: museo-sans-condensed, sans-serif;
  font-weight: 500;
  text-align: center;
  font-size: 3.5em;
  color: #fff;
  margin-top: 50px;
  display: block;
}

.bottom-border-short {
  width: 200px;
  height: 5px;
  background-color: #fff;
  margin: auto;
  padding: 0;
  display: block;
  margin-top: 45px;
}

.wwd-content p {
  /* width: 90%; */
  margin-top: 45px;
  color: #fff;
  font-family: museo-sans-condensed, sans-serif;
  font-weight: 500;
  text-align: center;
  font-size: 1.25em;
  margin-bottom: 0;
}

.wwd-show .wwd-icon {
  height: 100px;
  margin-top: 35px;
  padding-left: 20px;
}

.wwd-show .wwd-icon-cube {
  padding: 0;
}
.wwd-show span {
  font-family: museo-sans-condensed, sans-serif;
  font-weight: 600;
  text-align: center;
  font-size: 1.5em;
  color: #fff;
  text-align: center;
  margin-top: 20px;
}

.wwd-show p {
  width: 80%;
  font-family: museo-sans-condensed, sans-serif;
  font-weight: 500;
  font-style: italic;
  text-align: center;
  font-size: 1.3em;
  color: #fff;
  text-align: center;
  margin-top: 20px;
}
