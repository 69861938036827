.team {
  margin-top: 40px;
}

.Mtt.container-fluid {
  background-color: #316d93;
  padding: 0;
  padding-bottom: 80px;

}

.down-pointer-mtt .up {
  border-color: transparent transparent #fff transparent;
  margin-top: 80px;
}

/* .team-pic {
  width
} */


.card-img-top {
  min-height: 402px;
}
