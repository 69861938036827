.Trends.container-fluid {
  background-color: #316d93;
  background: url(./../../Assets/trends-bg.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: top top;
}

.trend-icon {
  width: 100%;
  margin-top: 45px;
}

.alt-trend {
  margin-top: 165px;
}

p.trends-text {
  color: #fff;
  margin-top: 30px;
  text-align: center;
  font-size: 0.9em;
}

.progress-wrapper {
  text-align: center;
}

.progress-wrapper svg {
  margin-top: 20px
}

.progress-wrapper:nth-child(even) svg {
  margin-top: 100px;
  /* background-color: red; */
}

@media (max-width: 1025px) {
  .progress-wrapper:nth-child(even) svg {
    margin-top: 20px;
  }

  .progress-wrapper {
    padding: 0 10px;
  }
}
